<template>
  <div class="about">
    <h1>AI Playground</h1>
    <h3>2023 by Patrik H&uuml;bner</h3>
    <p class="infoText">An ongoing series of experiments in the realm of artificial intelligence. This page will be updated soon with more detailed information.</p>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/theming.scss";

.about {
  padding: 20px;
}
.infoText {
  @include theme(color, secondaryTextColor);
  font-size: 12px;
  max-width: 400px;
}
</style>